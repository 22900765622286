<template>
    <section class="logout-page">
        <h1>Logging out...</h1>
    </section>
</template>

<script>
// Routes
import LoginRoute from '@/router/routes/Login';

export default {
    name: 'Logout',
    async created() {
        await this.$store.dispatch( 'User/logout' );
        await this.$router.push( { name: LoginRoute.name } );
    },
};
</script>

<style lang="scss" scoped>
.logout-page {
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
    background-color: var(--v-primary-base);
    color: white;

    h1 {
        margin: auto;
    }
}
</style>
